const Prod = {
  // SERVER_URL: 'https://claimstatus.hdfcergo.com/hcs',
  SERVER_URL: 'https://kompass.hdfcergo.com/hcs',
  // SERVER_URL: "http://13.232.203.189/rapid-os-server",
  // SERVER_URL: 'https://hcs-uat.hdfcergo.com/hcs',
  JWT_SECRET: "_h_fc_rapid_os",
  ENCRYPT_DECRYPT_SECRET_KEY: "948935ad-f3c0-4841-af01-8f2f26a634c2",
};

export default Prod;
